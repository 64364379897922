import React from 'react';
import { Check } from 'lucide-react';

const plans = [
  {
    name: 'Starter',
    subtitle: 'For freelancers and small teams',
    price: 99,
    link: 'https://app.searchatlas.com/signup?plan=starter&fpr=ken22',
    features: [
      '1 OTTO SEO Site Activation',
      'OTTO PPC (coming soon)',
      '5 GSC Site Projects',
      '3 GBP Accounts',
      '2 User Seats',
      'Content Genius - AI Blog and Landing Page Generation',
      '1-click Publishing to WP and Shopify',
      'Aggregator Network Local Citations',
      'Press Release Distribution (via SignalGenesys)',
      'Report Builder + AI Storytelling',
      'Site Explorer',
      'Local SEO Heatmaps/Geogrids',
      'Keyword Explorer, Keyword Magic, Rank Tracker, and Gap Analysis',
      'GSC Unlimited Keyword Rank Tracking',
      'Keyword, Competitor & Backlink Research Tools',
      'Chrome Extension Access',
      'Authority Building',
      'Cloud Stacks',
      'Press Release Generation (via Signal Genesys)',
      'Digital PR / AI-driven Outreach',
      'Backlink Exchange (coming soon)'
    ]
  },
  {
    name: 'Growth',
    subtitle: 'For agencies and mid-size businesses',
    price: 199,
    link: 'https://app.searchatlas.com/signup?plan=growth&fpr=ken22',
    features: [
      '1 OTTO SEO Site Activation',
      'OTTO PPC (coming soon)',
      '15 GSC Site Projects',
      '10 GBP Accounts',
      'All Starter features',
      'Site Auditor with Realtime Site Monitoring',
      'HARO AI & Digital PR',
      'Journalist Outreach',
      'Expanded quotas',
      'Batch Analysis',
      'AI SEO Recommendation Engine'
    ]
  },
  {
    name: 'Pro',
    subtitle: 'Agencies ready to scale',
    price: 399,
    link: 'https://app.searchatlas.com/signup?plan=pro&fpr=ken22',
    popular: true,
    features: [
      '3 OTTO SEO Sites Activation',
      'OTTO PPC (coming soon)',
      'Unlimited GSC Site Projects',
      '25 GBP Accounts',
      'All Growth features',
      'Unlimited GSC Keyword Rank Tracking',
      'AI Generation Quota',
      'Unlimited Site Crawls up to 1 million pages',
      'Full Dashboard Whitelabel',
      'Bulk Workflows for AI content creation',
      'Site Explorer',
      'Page Audits',
      'Site Audits',
      'URL Analysis',
      'Site Analysis Lead Capture Widget',
      'Integrations',
      'Link Building / Sales Outreach'
    ]
  }
];

export function Pricing() {
  return (
    <section id="pricing" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-otto-purple mb-4">
            Choose Your Plan
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Flexible pricing options to match your SEO needs
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`rounded-lg overflow-hidden ${
                plan.popular
                  ? 'transform scale-105 shadow-xl border-2 border-otto-purple'
                  : 'border border-gray-200 shadow-lg'
              }`}
            >
              <div className={`p-8 ${plan.popular ? 'bg-otto-purple' : 'bg-white'}`}>
                <h3 className={`text-2xl font-bold mb-1 ${
                  plan.popular ? 'text-white' : 'text-otto-dark'
                }`}>
                  {plan.name}
                </h3>
                <p className={`text-sm mb-4 ${
                  plan.popular ? 'text-white/80' : 'text-gray-500'
                }`}>
                  {plan.subtitle}
                </p>
                <div className="flex items-baseline mb-4">
                  <span className={`text-4xl font-bold ${
                    plan.popular ? 'text-white' : 'text-otto-purple'
                  }`}>
                    ${plan.price}
                  </span>
                  <span className={`ml-2 ${
                    plan.popular ? 'text-white/80' : 'text-gray-500'
                  }`}>
                    /month
                  </span>
                </div>
                <a
                  href={plan.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`block w-full py-3 rounded-md font-semibold text-center transition-colors ${
                    plan.popular
                      ? 'bg-otto-yellow text-otto-purple hover:bg-yellow-300'
                      : 'bg-otto-purple text-white hover:bg-otto-dark'
                  }`}
                >
                  Start for Free
                </a>
              </div>
              <div className="bg-white p-8">
                <ul className="space-y-4">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start">
                      <Check className="h-5 w-5 text-otto-purple mr-3 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}