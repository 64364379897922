import React from 'react';
import {
  Hero,
  Features,
  OneClickSEO,
  Testimonials,
  CaseStudies,
  Pricing,
  FAQ
} from '../components';

export function Landing() {
  return (
    <div className="min-h-screen">
      <Hero />
      <Features />
      <OneClickSEO />
      <Testimonials />
      <CaseStudies />
      <Pricing />
      <FAQ />
    </div>
  );
}