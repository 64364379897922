import React from 'react';

export function Terms() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold text-otto-purple mb-8">Terms of Service</h1>
      
      <div className="prose prose-lg">
        <h2>1. Terms</h2>
        <p>By accessing OTTO, you agree to be bound by these terms of service and agree that you are responsible for compliance with any applicable local laws.</p>

        <h2>2. Use License</h2>
        <p>Permission is granted to temporarily access OTTO for personal, non-commercial transitory viewing only.</p>

        <h2>3. Disclaimer</h2>
        <p>The materials on OTTO are provided on an 'as is' basis. OTTO makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

        <h2>4. Limitations</h2>
        <p>In no event shall OTTO or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use OTTO.</p>

        <h2>5. Accuracy of Materials</h2>
        <p>The materials appearing on OTTO could include technical, typographical, or photographic errors. OTTO does not warrant that any of the materials on its website are accurate, complete, or current.</p>

        <h2>6. Links</h2>
        <p>OTTO has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site.</p>

        <h2>7. Modifications</h2>
        <p>OTTO may revise these terms of service at any time without notice. By using this website, you are agreeing to be bound by the then current version of these terms of service.</p>
      </div>
    </div>
  );
}