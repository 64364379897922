import React from 'react';
import { Star, Quote } from 'lucide-react';

const testimonials = [
  {
    name: 'Sarah Johnson',
    role: 'Marketing Director',
    company: 'TechCorp',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200',
    content: 'OTTO has transformed our SEO strategy. The AI-powered insights helped us increase organic traffic by 150% in just three months.',
    rating: 5
  },
  {
    name: 'Michael Chen',
    role: 'SEO Specialist',
    company: 'Growth Agency',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200',
    content: 'The real-time updates and competitor tracking features are game-changers. We can now make data-driven decisions faster than ever.',
    rating: 5
  },
  {
    name: 'Emily Rodriguez',
    role: 'Content Manager',
    company: 'Digital Solutions',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200',
    content: 'The keyword research tools and content optimization suggestions have helped us rank for highly competitive terms consistently.',
    rating: 5
  }
];

export function Testimonials() {
  return (
    <section id="testimonials" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-otto-purple mb-4">
            Trusted by Industry Leaders
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            See how OTTO is helping businesses achieve their SEO goals
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-8 relative"
            >
              <Quote className="absolute top-4 right-4 h-8 w-8 text-otto-purple/10" />
              
              <div className="flex items-center mb-6">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="h-14 w-14 rounded-full object-cover mr-4 border-2 border-otto-purple"
                />
                <div>
                  <h3 className="font-semibold text-otto-dark">{testimonial.name}</h3>
                  <p className="text-sm text-gray-500">
                    {testimonial.role} at {testimonial.company}
                  </p>
                </div>
              </div>

              <div className="flex mb-4">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star
                    key={i}
                    className="h-5 w-5 text-otto-yellow fill-current"
                  />
                ))}
              </div>

              <p className="text-gray-600 italic">"{testimonial.content}"</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}