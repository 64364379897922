import React from 'react';
import { Brain, Target, Zap, BarChart3, Search, Lock } from 'lucide-react';

const features = [
  {
    icon: Brain,
    title: 'AI-Powered Analysis',
    description: 'Advanced machine learning algorithms analyze your content and provide actionable insights.'
  },
  {
    icon: Target,
    title: 'Competitor Tracking',
    description: "Monitor your competitors' strategies and stay ahead in your industry."
  },
  {
    icon: Zap,
    title: 'Real-time Updates',
    description: 'Get instant notifications about ranking changes and optimization opportunities.'
  },
  {
    icon: BarChart3,
    title: 'Performance Metrics',
    description: 'Comprehensive analytics dashboard with key performance indicators.'
  },
  {
    icon: Search,
    title: 'Keyword Research',
    description: 'Discover high-value keywords with our advanced research tools.'
  },
  {
    icon: Lock,
    title: 'Data Security',
    description: 'Enterprise-grade security to protect your sensitive information.'
  }
];

export function Features() {
  return (
    <section id="features" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-otto-purple mb-4">
            Powerful Features for SEO Success
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Everything you need to optimize your content and improve your search rankings
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="p-6 rounded-lg border border-gray-100 hover:shadow-lg transition-shadow"
            >
              <feature.icon className="h-12 w-12 text-otto-yellow mb-4" />
              <h3 className="text-xl font-semibold text-otto-dark mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}