import { useParams } from 'react-router-dom';

interface BlogPost {
  title: string;
  date: string;
  readTime: string;
  category: string;
  content: string;
}

interface BlogPosts {
  [key: string]: BlogPost;
}

const blogPosts: BlogPosts = {
  'f44bce44-ec0d-4b3b-bcd9-93aa9dbb4d2f': {
    title: 'The Ultimate Guide to Technical SEO',
    date: '2024-03-15',
    readTime: '8 min read',
    category: 'Technical SEO',
    content: `Technical SEO is the foundation of any successful SEO strategy...`
  }
  // Add other blog posts here
};

export function BlogPost() {
  const { id } = useParams<{ id: string }>();
  const post = id && blogPosts[id] ? blogPosts[id] : null;

  if (!post) {
    return <div>Blog post not found</div>;
  }

  return (
    <article className="max-w-4xl mx-auto px-4 py-16">
      <header className="mb-8">
        <h1 className="text-4xl font-bold text-otto-purple mb-4">{post.title}</h1>
        <div className="flex items-center text-gray-600 space-x-4">
          <time dateTime={post.date}>{post.date}</time>
          <span>•</span>
          <span>{post.readTime}</span>
          <span>•</span>
          <span className="text-otto-purple">{post.category}</span>
        </div>
      </header>
      <div className="prose prose-lg max-w-none">
        {post.content}
      </div>
    </article>
  );
}