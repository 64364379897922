import React from 'react';
import { Sparkles, Zap, Brain, Target, BarChart3, Globe } from 'lucide-react';

export function OneClickSEO() {
  return (
    <section id="one-click-seo" className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-otto-purple mb-4">
            One-Click SEO Revolution
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Transform your content strategy with AI-powered optimization
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-16">
          <div>
            <img
              src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=1024&q=80"
              alt="AI SEO Assistant Interface"
              className="rounded-lg shadow-xl mb-8 object-cover aspect-video"
            />
            <div className="space-y-6">
              <h3 className="text-2xl font-bold text-otto-dark">
                Meet OTTO: Your AI SEO Assistant
              </h3>
              <p className="text-gray-600">
                OTTO represents the next evolution in SEO technology, combining advanced artificial intelligence with years of SEO expertise. This revolutionary platform transforms how businesses approach search engine optimization, making enterprise-level SEO accessible to everyone.
              </p>
              <p className="text-gray-600">
                Unlike traditional SEO tools that simply provide data, OTTO actively analyzes your content and provides real-time optimization suggestions. It's like having an expert SEO consultant available 24/7, constantly monitoring and improving your website's performance.
              </p>
            </div>
          </div>

          <div>
            <img
              src="https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?auto=format&fit=crop&w=1024&q=80"
              alt="Content Generation Dashboard"
              className="rounded-lg shadow-xl mb-8 object-cover aspect-video"
            />
            <div className="space-y-6">
              <h3 className="text-2xl font-bold text-otto-dark">
                AI-Powered Content Creation
              </h3>
              <p className="text-gray-600">
                OTTO's content generation capabilities go beyond simple text creation. The platform understands search intent, user behavior, and content relevance, ensuring that every piece of content it helps create is optimized for both search engines and human readers.
              </p>
              <p className="text-gray-600">
                With OTTO's advanced natural language processing, you can generate blog posts, product descriptions, meta tags, and more - all optimized for your target keywords and audience. The platform maintains your brand voice while ensuring SEO best practices are followed.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-8 mb-16">
          <h3 className="text-2xl font-bold text-otto-dark mb-8 text-center">
            The Science Behind One-Click SEO
          </h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <h4 className="text-xl font-semibold text-otto-purple">Advanced Algorithm Analysis</h4>
              <p className="text-gray-600">
                OTTO employs sophisticated algorithms that analyze over 200+ ranking factors in real-time. The system continuously learns from successful content patterns across different industries and niches, applying these insights to your optimization strategy.
              </p>
              <p className="text-gray-600">
                The platform's neural networks process vast amounts of data to understand search engine behavior, user intent, and content performance metrics. This deep learning approach enables OTTO to make highly accurate optimization recommendations that drive results.
              </p>
            </div>

            <div className="space-y-6">
              <h4 className="text-xl font-semibold text-otto-purple">Real-Time Optimization</h4>
              <p className="text-gray-600">
                Every time you create or modify content, OTTO analyzes it in real-time against current SEO best practices. The platform provides instant feedback and suggestions for improvement, from keyword placement to content structure and readability.
              </p>
              <p className="text-gray-600">
                OTTO's real-time analysis includes checking for keyword density, LSI keywords, content depth, and semantic relevance. The platform also monitors your competitors' content strategies, ensuring your content remains competitive in your niche.
              </p>
            </div>
          </div>
        </div>

        <div className="mb-16">
          <img
            src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&w=1024&q=80"
            alt="Analytics Dashboard"
            className="rounded-lg shadow-xl mb-8 w-full object-cover aspect-video"
          />
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <h3 className="text-2xl font-bold text-otto-dark">
                Comprehensive SEO Suite
              </h3>
              <p className="text-gray-600">
                OTTO integrates all essential SEO tools into one unified platform. From keyword research and content optimization to technical SEO audits and backlink analysis, everything you need is available with just one click.
              </p>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-start">
                  <Globe className="h-6 w-6 text-otto-purple mr-2 flex-shrink-0" />
                  <span>Site-wide SEO analysis and monitoring</span>
                </li>
                <li className="flex items-start">
                  <BarChart3 className="h-6 w-6 text-otto-purple mr-2 flex-shrink-0" />
                  <span>Advanced performance tracking and reporting</span>
                </li>
                <li className="flex items-start">
                  <Sparkles className="h-6 w-6 text-otto-purple mr-2 flex-shrink-0" />
                  <span>AI-powered content optimization suggestions</span>
                </li>
              </ul>
            </div>

            <div className="space-y-6">
              <h3 className="text-2xl font-bold text-otto-dark">
                Enterprise-Grade Features
              </h3>
              <p className="text-gray-600">
                OTTO brings enterprise-level SEO capabilities to businesses of all sizes. The platform's advanced features include:
              </p>
              <ul className="space-y-4 text-gray-600">
                <li>• Automated content briefs and outlines</li>
                <li>• Natural language processing for content optimization</li>
                <li>• Competitive analysis and gap identification</li>
                <li>• Technical SEO audit automation</li>
                <li>• Content performance tracking</li>
                <li>• Automated reporting and insights</li>
                <li>• Integration with popular CMS platforms</li>
                <li>• Custom workflows and team collaboration</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-otto-purple rounded-xl p-8 text-white">
          <div className="max-w-3xl mx-auto text-center">
            <h3 className="text-2xl font-bold mb-6">
              The Future of SEO is Here
            </h3>
            <p className="mb-8">
              OTTO represents a paradigm shift in how SEO is performed. By combining artificial intelligence with proven SEO strategies, we're making it possible for businesses to achieve outstanding search engine rankings with less effort and greater precision than ever before.
            </p>
            <a
              href="https://searchatlas.com/otto-seo/?fpr=ken22"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-8 py-4 bg-otto-yellow text-otto-purple rounded-lg hover:bg-yellow-300 transition-colors font-semibold"
            >
              Experience One-Click SEO
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}