import React from 'react';
import { Calendar, Clock, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const blogPosts = [
  {
    id: '3a4960bb-0915-4967-9d44-19462c6215c0',
    title: 'The Ultimate Guide to SEO Content Writing',
    excerpt: 'Learn how to create SEO-optimized content that ranks well and engages readers.',
    date: '2024-01-15',
    readTime: '10 min read',
    category: 'Content SEO',
    author: 'Ken Tran'
  },
  {
    id: 'eebcd6c0-2193-43f6-a6a5-252916c0a7fe',
    title: 'Local SEO Strategies for 2024',
    excerpt: 'Master local SEO with proven strategies to improve your local search rankings.',
    date: '2024-01-20',
    readTime: '8 min read',
    category: 'Local SEO',
    author: 'Ken Tran'
  },
  {
    id: 'f44bce44-ec0d-4b3b-bcd9-93aa9dbb4d2f',
    title: 'Mastering Technical SEO',
    excerpt: 'Dive deep into technical SEO fundamentals and optimize your website performance.',
    date: '2024-01-25',
    readTime: '12 min read',
    category: 'Technical SEO',
    author: 'Ken Tran'
  }
];

export function Blog() {
  return (
    <section id="blog" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-otto-purple mb-4">
            Latest SEO Insights
          </h2>
          <p className="text-xl text-gray-600">
            Stay updated with the latest trends and strategies in SEO
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <article
              key={post.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
            >
              <div className="p-6">
                <div className="flex items-center text-sm text-gray-500 mb-4">
                  <Calendar className="h-4 w-4 mr-2" />
                  <span>{post.date}</span>
                  <span className="mx-2">•</span>
                  <Clock className="h-4 w-4 mr-2" />
                  <span>{post.readTime}</span>
                </div>
                <span className="inline-block bg-otto-purple text-white px-3 py-1 rounded-full text-sm mb-4">
                  {post.category}
                </span>
                <h3 className="text-xl font-bold text-otto-dark mb-2">
                  {post.title}
                </h3>
                <p className="text-gray-600 mb-4">
                  {post.excerpt}
                </p>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-500">{post.author}</span>
                  <Link
                    to={`/blog/${post.id}`}
                    className="inline-flex items-center text-otto-purple hover:text-otto-dark transition-colors font-medium"
                  >
                    Read More
                    <ChevronRight className="h-4 w-4 ml-1" />
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
}