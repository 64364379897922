import React from 'react';
import { Facebook, Twitter, Linkedin, Instagram, Mail, Phone, LucideIcon } from 'lucide-react';
import { Button } from './Button';

interface SocialLinkProps {
  href: string;
  icon: LucideIcon;
}

interface FooterLinksProps {
  links: { text: string; href: string }[];
}

const contactInfo = {
  email: "ken@seoraleigh.net",
  phone: "9192714871", // Removed formatting for proper auto-dial
  formattedPhone: "(919) 271-4871", // Formatted for display
  website: "https://seoraleigh.net"
};

const SocialLink = ({ href, icon: Icon }: SocialLinkProps) => (
  <a
    href={href}
    className="h-8 w-8 flex items-center justify-center rounded-full bg-otto-light/20 hover:bg-otto-yellow text-white hover:text-otto-purple transition-colors"
  >
    <Icon className="h-4 w-4" />
  </a>
);

const FooterLinks = ({ links }: FooterLinksProps) => (
  <ul className="space-y-2">
    {links.map((link, index) => (
      <li key={index}>
        <a
          href={link.href}
          className="text-otto-light hover:text-white transition-colors"
          {...(link.href.startsWith('http') ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
        >
          {link.text}
        </a>
      </li>
    ))}
  </ul>
);

export function Footer() {
  return (
    <footer className="bg-otto-dark text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">SEO Raleigh</h3>
            <p className="text-otto-light mb-4">
              Raleigh's Premier SEO Agency - Transform your digital presence with AI-powered insights and local expertise.
            </p>
            <div className="flex flex-col space-y-2 mb-4">
              <a href={`tel:+1${contactInfo.phone}`} className="flex items-center text-otto-light hover:text-white">
                <Phone className="h-4 w-4 mr-2" />
                {contactInfo.formattedPhone}
              </a>
              <a href={`mailto:${contactInfo.email}`} className="flex items-center text-otto-light hover:text-white">
                <Mail className="h-4 w-4 mr-2" />
                {contactInfo.email}
              </a>
            </div>
            <Button 
              variant="secondary" 
              className="w-full mb-4"
              onClick={() => window.location.href = `tel:+1${contactInfo.phone}`}
            >
              <Phone className="h-4 w-4 mr-2" />
              Call for Free Consultation
            </Button>
            <div className="flex space-x-4">
              <SocialLink href="#" icon={Facebook} />
              <SocialLink href="#" icon={Twitter} />
              <SocialLink href="#" icon={Linkedin} />
              <SocialLink href="#" icon={Instagram} />
            </div>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-4">Services</h4>
            <FooterLinks
              links={[
                { text: 'Local SEO', href: '#features' },
                { text: 'Technical SEO', href: '#features' },
                { text: 'Content Optimization', href: '#features' },
                { text: 'Link Building', href: '#features' }
              ]}
            />
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-4">Company</h4>
            <FooterLinks
              links={[
                { text: 'About Us', href: '/about' },
                { text: 'Blog', href: '/blog' },
                { text: 'Case Studies', href: '#case-studies' }
              ]}
            />
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-4">Legal & Help</h4>
            <FooterLinks
              links={[
                { text: 'Contact Us', href: `tel:+1${contactInfo.phone}` },
                { text: 'Terms of Service', href: '/terms' },
                { text: 'Privacy Policy', href: '/privacy' }
              ]}
            />
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-otto-light/30">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-otto-light text-sm mb-4 md:mb-0">
              © {new Date().getFullYear()} SEO Raleigh. All rights reserved.
            </p>
            <a 
              href={`tel:+1${contactInfo.phone}`}
              className="flex items-center text-sm text-otto-light hover:text-white transition-colors"
            >
              <Phone className="h-4 w-4 mr-2" />
              Call Now: {contactInfo.formattedPhone}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}