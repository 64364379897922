import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Mail, Phone, Globe } from 'lucide-react';
import { Button } from './Button';

const contactInfo = {
  email: "ken@seoraleigh.net",
  phone: "9192714871",
  formattedPhone: "(919) 271-4871",
  website: "https://seoraleigh.net"
};

export function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const faqs = [
    {
      question: "Why choose SEO Raleigh for your digital marketing?",
      answer: "As Raleigh's premier SEO agency, we combine local expertise with cutting-edge AI technology to deliver superior results. Our proven track record and personalized approach ensure your business gets the visibility it deserves in the Triangle area and beyond."
    },
    {
      question: "What makes SEO Raleigh different from other agencies?",
      answer: "We're not just another SEO company - we're your local digital growth partner. With our AI-powered tools and deep understanding of the Raleigh market, we provide customized solutions that drive real results for your business."
    },
    {
      question: "How quickly can I expect to see results?",
      answer: "While SEO is a long-term strategy, our clients typically start seeing improvements in their search visibility within 3-6 months. We provide regular updates and transparent reporting so you can track your progress every step of the way."
    },
    {
      question: "Do you offer local SEO services?",
      answer: "Absolutely! As Raleigh's local SEO experts, we specialize in helping businesses dominate local search results. Our strategies are specifically designed to attract customers in the Triangle area and surrounding regions."
    },
    {
      question: "What industries do you serve?",
      answer: "We work with businesses across all industries in the Raleigh-Durham area. From local restaurants and retail stores to professional services and healthcare providers, our SEO strategies are customized to your specific industry needs."
    },
    {
      question: "What's included in your SEO services?",
      answer: "Our comprehensive SEO services include keyword research, on-page optimization, technical SEO, content creation, local SEO, link building, and monthly reporting. We also provide regular strategy sessions to keep your campaign aligned with your business goals."
    },
    {
      question: "How do you measure SEO success?",
      answer: "We track multiple metrics including keyword rankings, organic traffic, conversion rates, local visibility, and ROI. Our detailed monthly reports show exactly how your SEO investment is performing and where we're focusing our efforts."
    },
    {
      question: "Do you require long-term contracts?",
      answer: "No, we don't believe in locking clients into long-term contracts. While SEO is a long-term strategy, we earn your business month after month through results and transparent communication."
    },
    {
      question: "Can you help with website design and development?",
      answer: "Yes, we offer comprehensive website design and development services that are built with SEO in mind from the ground up. Our team ensures your website is not only beautiful but also optimized for search engines and conversions."
    },
    {
      question: "How do I get started with SEO Raleigh?",
      answer: "Getting started is easy! Simply call us at (919) 271-4871 for a free consultation. We'll discuss your business goals, analyze your current online presence, and create a customized SEO strategy tailored to your needs."
    }
  ];

  return (
    <section id="faq" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-otto-purple mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-xl text-gray-600">
            Get answers to common questions about SEO Raleigh's services
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-20">
          <div className="space-y-4">
            {faqs.slice(0, 5).map((faq, index) => (
              <div
                key={index}
                className="border border-gray-200 rounded-lg overflow-hidden"
              >
                <button
                  className="w-full px-6 py-4 flex justify-between items-center bg-gray-50 hover:bg-gray-100 transition-colors"
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                >
                  <span className="text-lg font-semibold text-otto-dark">
                    {faq.question}
                  </span>
                  {openIndex === index ? (
                    <ChevronUp className="h-5 w-5 text-otto-purple" />
                  ) : (
                    <ChevronDown className="h-5 w-5 text-otto-purple" />
                  )}
                </button>
                {openIndex === index && (
                  <div className="px-6 py-4 bg-white">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="space-y-4">
            {faqs.slice(5).map((faq, index) => (
              <div
                key={index + 5}
                className="border border-gray-200 rounded-lg overflow-hidden"
              >
                <button
                  className="w-full px-6 py-4 flex justify-between items-center bg-gray-50 hover:bg-gray-100 transition-colors"
                  onClick={() => setOpenIndex(openIndex === index + 5 ? null : index + 5)}
                >
                  <span className="text-lg font-semibold text-otto-dark">
                    {faq.question}
                  </span>
                  {openIndex === index + 5 ? (
                    <ChevronUp className="h-5 w-5 text-otto-purple" />
                  ) : (
                    <ChevronDown className="h-5 w-5 text-otto-purple" />
                  )}
                </button>
                {openIndex === index + 5 && (
                  <div className="px-6 py-4 bg-white">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 bg-gray-50 rounded-xl p-8">
          <div>
            <h3 className="text-2xl font-bold text-otto-purple mb-6">
              Get in Touch
            </h3>
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <Phone className="h-5 w-5 text-otto-purple" />
                <a href={`tel:+1${contactInfo.phone}`} className="text-gray-600 hover:text-otto-purple">
                  {contactInfo.formattedPhone}
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <Mail className="h-5 w-5 text-otto-purple" />
                <a href={`mailto:${contactInfo.email}`} className="text-gray-600 hover:text-otto-purple">
                  {contactInfo.email}
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <Globe className="h-5 w-5 text-otto-purple" />
                <a href={contactInfo.website} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-otto-purple">
                  {contactInfo.website}
                </a>
              </div>
            </div>
            <Button
              variant="primary"
              className="w-full mt-6"
              onClick={() => window.location.href = `tel:+1${contactInfo.phone}`}
            >
              <Phone className="h-4 w-4 mr-2" />
              Call Now for Free Consultation
            </Button>
          </div>

          <div>
            <h3 className="text-2xl font-bold text-otto-purple mb-6">
              Send Us a Message
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-otto-purple focus:border-otto-purple"
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-otto-purple focus:border-otto-purple"
                  required
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-otto-purple focus:border-otto-purple"
                  required
                />
              </div>
              <Button type="submit" variant="primary" className="w-full">
                Send Message
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}