import React, { useState } from 'react';
import { Menu, X, Search, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from './Button';

const contactInfo = {
  phone: "9192714871", // Removed formatting for proper auto-dial
  formattedPhone: "(919) 271-4871" // Formatted for display
};

const NavLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  const isExternal = href.startsWith('#');
  
  if (isExternal) {
    return (
      <a
        href={href}
        className="block py-2 md:py-0 text-white hover:text-otto-yellow transition-colors font-medium"
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={href}
      className="block py-2 md:py-0 text-white hover:text-otto-yellow transition-colors font-medium"
    >
      {children}
    </Link>
  );
};

export function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-otto-purple sticky top-0 z-50">
      <div className="bg-otto-dark text-white py-2">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <p className="text-sm">Raleigh's Premier SEO Agency</p>
            <a 
              href={`tel:+1${contactInfo.phone}`}
              className="flex items-center text-sm hover:text-otto-yellow transition-colors"
            >
              <Phone className="h-4 w-4 mr-2" />
              {contactInfo.formattedPhone}
            </a>
          </div>
        </div>
      </div>
      
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <Search className="h-8 w-8 text-white" />
              <span className="ml-2 text-2xl font-bold text-white">SEO Raleigh</span>
            </Link>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            <NavLink href="#features">Features</NavLink>
            <NavLink href="#one-click-seo">One-Click SEO</NavLink>
            <NavLink href="#pricing">Pricing</NavLink>
            <NavLink href="#testimonials">Testimonials</NavLink>
            <NavLink href="#faq">FAQ</NavLink>
            <NavLink href="/blog">Blog</NavLink>
            <Button 
              variant="secondary"
              onClick={() => window.location.href = `tel:+1${contactInfo.phone}`}
              className="flex items-center"
            >
              <Phone className="h-4 w-4 mr-2" />
              Call Now
            </Button>
          </div>

          <div className="md:hidden flex items-center">
            <a 
              href={`tel:+1${contactInfo.phone}`}
              className="mr-4 text-white hover:text-otto-yellow"
            >
              <Phone className="h-6 w-6" />
            </a>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white hover:text-gray-200"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden pb-4 space-y-4">
            <div className="space-y-2">
              <NavLink href="#features">Features</NavLink>
              <NavLink href="#one-click-seo">One-Click SEO</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
              <NavLink href="#testimonials">Testimonials</NavLink>
              <NavLink href="#faq">FAQ</NavLink>
              <NavLink href="/blog">Blog</NavLink>
            </div>
            <Button 
              variant="secondary" 
              className="w-full flex items-center justify-center"
              onClick={() => window.location.href = `tel:+1${contactInfo.phone}`}
            >
              <Phone className="h-4 w-4 mr-2" />
              Call Now
            </Button>
          </div>
        )}
      </nav>
    </header>
  );
}