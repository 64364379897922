import React from 'react';

export function Privacy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold text-otto-purple mb-8">Privacy Policy</h1>
      
      <div className="prose prose-lg">
        <h2>1. Information We Collect</h2>
        <p>We collect information that you provide directly to us, information we obtain automatically when you use our service, and information from third-party sources.</p>

        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect to provide, maintain, and improve our services, to develop new ones, and to protect OTTO and our users.</p>

        <h2>3. Information Sharing and Disclosure</h2>
        <p>We do not share, sell, rent, or trade your personal information with third parties for their commercial purposes.</p>

        <h2>4. Data Retention</h2>
        <p>We retain your information for as long as your account is active or as needed to provide you services, comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

        <h2>5. Security</h2>
        <p>We take reasonable measures to help protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.</p>

        <h2>6. Your Rights</h2>
        <p>You have the right to access, update, or delete your information and to opt out of certain uses of your information.</p>

        <h2>7. Changes to this Policy</h2>
        <p>We may change this privacy policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions about this privacy policy or our practices, please contact us.</p>
      </div>
    </div>
  );
}