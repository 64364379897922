import React from 'react';
import { ArrowUpRight, MapPin, TrendingUp, Users } from 'lucide-react';

const caseStudies = [
  {
    title: 'Local Business Growth',
    metric: '312%',
    description: 'Increase in local search visibility',
    icon: MapPin,
    stats: {
      views: '+285%',
      calls: '+167%',
      directions: '+198%'
    }
  },
  {
    title: 'Customer Engagement',
    metric: '245%',
    description: 'Growth in customer interactions',
    icon: Users,
    stats: {
      reviews: '+156%',
      responses: '+203%',
      bookings: '+178%'
    }
  },
  {
    title: 'Market Dominance',
    metric: '189%',
    description: 'Improvement in local rankings',
    icon: TrendingUp,
    stats: {
      rankings: '+145%',
      coverage: '+167%',
      conversion: '+134%'
    }
  }
];

export function CaseStudies() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-otto-purple mb-4">
            GBP Success Stories
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            See how businesses are transforming their local presence with OTTO
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
          {caseStudies.map((study, index) => (
            <div
              key={index}
              className="bg-gray-50 rounded-lg overflow-hidden hover:shadow-lg transition-shadow"
            >
              <div className="relative h-48 bg-otto-purple/10">
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                <study.icon className="absolute bottom-4 left-4 h-8 w-8 text-otto-yellow" />
              </div>
              
              <div className="p-8">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-2xl font-bold text-otto-dark">
                    {study.metric}
                  </h3>
                  <span className="px-4 py-1 bg-otto-purple/10 text-otto-purple rounded-full text-sm font-semibold">
                    {study.title}
                  </span>
                </div>
                
                <p className="text-gray-600 mb-6">
                  {study.description}
                </p>
                
                <div className="space-y-3">
                  {Object.entries(study.stats).map(([key, value]) => (
                    <div key={key} className="flex justify-between items-center">
                      <span className="text-gray-600 capitalize">{key}</span>
                      <span className="font-semibold text-otto-purple">{value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center">
          <a
            href="https://searchatlas.com/case-studies/"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-8 py-4 bg-otto-purple text-white rounded-lg hover:bg-otto-dark transition-colors group"
          >
            <span className="font-semibold">View All Case Studies</span>
            <ArrowUpRight className="ml-2 h-5 w-5 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
          </a>
        </div>
      </div>
    </section>
  );
}